class MessageImagePanel extends MessageFilePanel {
	constructor(attachment, message) {
		super(attachment, message);//, 'messaging-message-image');
		
		// this.$attachment.input('image').attr('src', `/detail/content/${attachment.id}/${attachment.fileName}`);
		// this.$attachment.input('image').on('load', async () => {
		// 	message.element.roomPanel.freezeScroll(() => {
		// 		this.$attachment.find('.attachment-image').show();
		// 		this.$attachment.find('.attachment-loading').remove();
		// 	});
		// }).on('error', () => {
		// 	this.$attachment.input('loader').hide();
		// 	this.$attachment.input('error').show();
		// });

		// this.$attachment.input('image').tooltip({
		// 	title: attachment.fileName,
		// 	container: 'body'
		// });
	}
}

MessagePanel.registerFileType(MessageImagePanel, 'png', 'jpeg', 'jpg');