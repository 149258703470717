class MessageFilePanel {
	constructor(attachment, message, template) {
		this.$attachment = util.model(template || 'messaging-message-file');

		// this.$attachment.input('image').attr('src', `/detail/content/${detail.id}/${detail.content}`);
		// this.$attachment.input('image').on('load', async () => {
		// 	message.element.roomPanel.freezeScroll(() => {
		// 		this.$attachment.input('image').show();
		// 	});
		// });

		this.$attachment.input('name').text(attachment.fileName);
		this.$attachment.input('link').attr('href', `/detail/content/${attachment.id}/${attachment.fileName}`);

		this.$attachment.input('direct-download').click(ev => {
			util.download(`/detail/content/${attachment.id}/${attachment.fileName}`);

			ev.preventDefault();
			ev.stopPropagation();
		}).tooltip({
			title: i18n.__DOWNLOAD,
			container: 'body'
		});

		let icon = 'icon-main-document';
		switch (attachment.fileExtension) {
			case 'pdf':
				icon = 'icon-main-pdf';
				break;
			case 'wav':
			case 'mp3':
				icon = 'icon-main-audio-file';
				break;
			case 'png':
			case 'jpeg':
			case 'jpg':
				icon = 'icon-main-image-file';
				break;

			default:
				break;
		}

		this.$attachment.input('icon').addClass(icon);
	}

	appendTo($element) {
		$element.append(this.$attachment);
	}
}

MessagePanel.registerFileType(MessageFilePanel, '*');