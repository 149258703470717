class Room extends EventEmitter {
	/**
	 *Creates an instance of Room.
	 * @param {*} values
	 * @param {Messaging} messaging
	 * @memberof Room
	 */
	constructor(values, messaging) {
		super();

		Object.assign(this, values);

		this.messaging = messaging;

		/** @type {Message[]} */
		this.messages = this.messages || [];

		for (let messagingUser of this.messagingUsers) {
			messagingUser.user = messaging.users.find(user => user.id == messagingUser.userId);
		}
		
		this.ownMessagingUser = this.messagingUsers.find(mU => mU.userId == Ingtech.user.id);
		
		Object.defineProperty(this, 'messaging', { value: messaging });
		
		this.insertMessages(this.messagingMessages);
	}

	update(changes) {
		let isTemp = !this.id;

		delete changes.messages;
		Object.assign(this, changes);

		for (let messagingUser of this.messagingUsers) {
			messagingUser.user = this.messaging.users.find(user => user.id == messagingUser.userId);
		}

		if (isTemp && this.id && this.bufferedMessagingMessages && this.bufferedMessagingMessages.length > 0) {
			this.messaging.publishMessage(this, this.bufferedMessagingMessages);
		}

		this.messaging.roomUpdated(this);
		this.messaging.roomUpdatedStatus(this);
	}

	sendMessage(message) {
		this.insertMessages(message);
	}

	receiveMessage(message) {
		if (message.sentById != Ingtech.user.id) {
			this.ownMessagingUser.unreadMessages++;

			if (message.priority == 1) {
				this.ownMessagingUser.unreadImportantMessages++;
				this.ownMessagingUser.lastImportantMessagingMessage = message;
				this.ownMessagingUser.lastImportantMessagingMessageId = message.id;
			}
		}

		this.insertMessages(message);
	}

	insertMessages(messages) {
		messages = messages instanceof Array ? messages : new Array(messages || 0);


		let newMessages = false;

		for (let message of messages) {
			if (message.messagingRoomId && this.id && message.messagingRoomId != this.id) continue;
			
			let matchingMessage = this.messages.find(m => (message.id && message.id == m.id) || (message.transactionId && message.transactionId == m.transactionId));

			if (matchingMessage) {
				matchingMessage.update(message);
			} else {
				Object.setPrototypeOf(message, Message.prototype);
				message.setRoom(this)

				this.messages.push(message);
			}
		}

		if (messages.length > 0) {
			this.sort();

			newMessages = this.lastSentMessagingMessage != this.messages[this.messages.length - 1];

			this.lastSentMessagingMessage = this.messages[this.messages.length - 1];
			this.lastSentMessagingMessageId = this.lastSentMessagingMessage.id;

			this.messaging.messagesUpdated(this);
			this.messaging.roomUpdatedStatus(this);
			if (newMessages) this.messaging.newMessage(this);
		}
	}

	sort() {
		util.advSort(this.messages, [
			['unsent', (a, b) => (a || false) - (b || false)],
			['sentAt', 'asc', 'date'],
			['id', 'asc']
		]);
	}

	read(force) {
		if ((this.ownMessagingUser.unreadMessages == 0 || !this.id) && !force) return;

		if (!page.isActive) return;

		let lastRead;
		for (let message of this.messages) {
			if (message.sentById != Ingtech.user.id) {
				lastRead = message;
			}
		}

		this.ownMessagingUser.lastReadAt = new Date().toJSON();
		this.ownMessagingUser.lastReadMessagingMessageId = lastRead ? lastRead.id : null;
		this.ownMessagingUser.unreadMessages = 0;

		this.ownMessagingUser.unreadImportantMessages = 0;
		this.ownMessagingUser.lastImportantMessagingMessage = null;
		this.ownMessagingUser.lastImportantMessagingMessageId = null;

		Ingtech.Notification.ackBySource('messaging', this.id);
		
		this.messaging.roomUpdatedStatus(this);
		this.messaging.broker.updateLastRead(this);

		this.messaging.roomUpdated(this);
	}

	getRead(messagingUser) {
		let oldMessagingUser = this.messagingUsers.find(mU => mU.id == messagingUser.id);
		Object.assign(oldMessagingUser, messagingUser);

		this.messaging.messagesUpdated(this);
	}

	addUsers(users) {
		let now = new Date().toISOString();

		this.messagingUsers.push(...users.map(user => ({
			user: user,
			userId: user.id,
			joinedAt: now,
			lastReadAt: null,
			unreadMessagingMessage: 0,
			seePreJoinMessage: true
		})));

		this.messaging.roomUpdated(this);
	}

	match(search) {
		if (!search) return true;

		if (this.messagingUsers.some(mU => mU.userId != Ingtech.user.id && util.strMatch(mU.user.fullName, search)))
			return true;

		if (this.messages.some(m => util.strMatch(m.content, search)))
			return true;
	}


	/**
	 *
	 *
	 * @param {Room} other
	 * @returns {number}
	 * @memberof Room
	 */
	compareTo(other) {
		// let isImportant = !!this.ownMessagingUser.unreadImportantMessages - !!other.ownMessagingUser.unreadImportantMessages;

		// if (isImportant != 0) return isImportant;

		return Math.sign(this.getRelevantDate().valueOf() - other.getRelevantDate().valueOf());
	}

	/**
	 *
	 *
	 * @memberof Room
	 * @returns {Date}
	 */
	getRelevantDate() {
		if (!this.ownMessagingUser.lastReadAt && this.createdById != Ingtech.user.id) {
			return moment.utc(this.ownMessagingUser.joinedAt);
		}

		return moment.utc(this.lastSentMessagingMessage ? this.lastSentMessagingMessage.sentAt : this.createdAt);
	}
}