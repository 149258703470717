class RoomsListPanel {
	constructor(options) {
		this.options = options;

		/** @type {Room[]} */
		this.rooms = [];


		this.$roomsList = util.model('messaging-rooms_list');
		this.$element = this.$roomsList;

		this.$list = this.$roomsList.input('rooms_list-container');

		this.search = '';

		this.$roomsList.input('new-room').click(() => {
			this.messaging.newRoomMenu();
			//Set a new class here
			$('.msg-main-panel').addClass("msg-hidden");
			$('.msg-side-panel').addClass("msg-active");
			console.log(window.location.pathname)
		});

		this.$list.click();

		// this.$list.click();
		// this.$list.click(() => {
		// 	$(".msg-main-panel").addClass("msg-hidden");
		// 	$(".msg-side-panel").addClass("msg-active");
		//   })

		this.$roomsList.input('search').on('paste cut keyup keypress change', () => {
			let search = this.$roomsList.input('search').val();

			if (search != this.search) {
				this.search = search;
				this.attachRooms();
			}
		})
	}

	/**
	 *
	 *
	 * @param {Messaging} parent
	 * @returns {this}
	 * @memberof RoomsList
	 */
	attachTo(parent) {
		this.parent = parent;
		this.messaging = parent.messaging;

		parent.$element.input('rooms_list-container').append(this.$roomsList);

		return this;
	}


	/**
	 *
	 *
	 * @param {Room[]} rooms
	 * @returns {this}
	 * @memberof RoomsList
	 */
	setRooms(rooms) {
		this.clear();

		this.addRooms(rooms);

		return this;
	}

	/**
	 *
	 *
	 * @param {Room[]|Room} rooms
	 * @returns {this}
	 * @memberof RoomsList
	 */
	addRooms(rooms, refresh = true) {
		rooms = rooms instanceof Array ? rooms : Array(rooms || 0);

		let roomsToAdd = [];

		for (let room of rooms) {
			try {
				room.banner = new RoomBannerPanel(room);
				room.banner.onClick(room => this.messaging.openRoom(room));

				roomsToAdd.push(room);
			} catch (err) {
				console.error('Corrupted room', room);
			}
		}

		this.rooms.push(...roomsToAdd);

		if (refresh) this.attachRooms();

		return this;
	}

	/**
	 *
	 *
	 * @param {MessagingBroker.RoomPreview} room
	 * @returns {this}
	 * @memberof RoomsList
	 */
	updateRoom(room, changes) {
		let isTemp = !room.id;

		Object.assign(room, changes);

		if (isTemp && room.id) {
			this.messaging.publishMessage(room, room.bufferedMessagingMessages);
		}
	}

	/**
	 *
	 *
	 * @param {MessagingBroker.Message} message
	 * @returns {this}
	 * @memberof RoomsList
	 */
	updateRoomMessage(room, refresh = true) {
		if (room.banner) room.banner.updateStatus();

		if (refresh) {
			this.attachRooms();
		}
	}


	readRoom(room, message) {
		let messagingUser = room.messagingUsers.find(mU => mU.userId == Ingtech.user.id);
		messagingUser.lastReadAt = moment.utc().format();
		messagingUser.lastReadMessagingMessageId = message ? message.id : null;
		messagingUser.unreadMessages = 0;

		room.banner.updateStatus();
	}

	selectRoom(room) {
		if (this.selectedRoom == room) return;
		if (this.selectedRoom) this.unselectRoom();

		this.selectedRoom = room;
		
		this.selectedRoom.banner.selected(true);

		// this.messaging.openRoom(room);
	}

	unselectRoom() {
		if (!this.selectedRoom) return;

		this.selectedRoom.banner.selected(false);
		this.selectedRoom = null;
	}


	addOrUpdateRoom(newRoom, refresh = true) {
		let room = this.rooms.find(room => room.id == newRoom.id || room.transactionId == newRoom.transactionId);

		if (room) {
			this.updateRoom(room, newRoom);
		} else {
			this.addRooms(newRoom, false);
		}

		if (refresh) {
			this.attachRooms();
		}

		return this;
	}


	/**
	 *
	 *
	 * @returns {this}
	 * @memberof RoomsList
	 */
	clear() {
		this.rooms.splice(0, this.rooms.length);

		return this;
	}


	/**
	 *
	 *
	 * @memberof RoomsList
	 */
	attachRooms() {
		this.$list.children().detach();

		this.rooms.sort((a, b) => -a.compareTo(b));


		let filteredRooms = this.rooms.filter(room => room.match(this.search));
		// util.searchIn(this.users, this.search, ['username', 'fullName', 'email'])
		// 	.filter(user => {
		// 		return !usersId.includes(user.id.toString());
		// 	})

		for (let room of filteredRooms) {
			room.banner.appendTo(this.$list);
		}
	}


	/**
	 *
	 *
	 * @memberof RoomsList
	 */
	displayError() {

	}


	markAsRead(room) {

	}


	findRoom(usersId) {
		for (let room of this.rooms) {
			if (usersId.length != room.messagingUsers.length) continue;
			if (room.messagingUsers.some(messagingUser => !usersId.includes(messagingUser.userId))) continue;
			
			return room;
		}
	}
}
