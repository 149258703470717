class MessagePanel {
	constructor(message, room) {
		this.message = message;
		this.room = room;

		if (message.ownMessage) {
			this.populateOwnMessage();
		} else {
			this.populateOtherMessage();
		}

		this.$message.input('date').text(moment.utc(message.sentAt).local().format('dddd DD MMM HH:mm'));
		this.$message.input('content').text(message.content);

		this.$message.input('sent').tooltip({ title: i18n.__SENT, container: 'body' });

		this.$message.find('.badge').tooltip({
			title: () => this.lastReadByName || '',
			container: 'body'
		});

		this.$message.find('.msg-text').toggleClass('important', message.priority == 1);
		this.$message.toggleClass('important', message.priority == 1);
		
		this.setAttachments(message.messagingAttachments);
	}

	setAttachments(attachments) {
		this.$message.find('.msg-attachments').html('');

		for (let attachment of attachments || []) {
			let Panel = MessagePanel._FILE_PANELS[attachment.fileExtension];
			if (!Panel) Panel = MessagePanel._FILE_PANELS['*'];

			if (Panel) {
				Object.defineProperty(attachment, 'element', { value: new Panel(attachment, this.message) });

				attachment.element.appendTo(this.$message.find('.msg-attachments'));
			}
		}
	}

	appendTo($element, roomPanel) {
		$element.append(this.$message);

		this.roomPanel = roomPanel;
	}

	populateOwnMessage() {
		this.$message = util.model('messaging-own_message');
	}

	populateOtherMessage() {
		this.$message = util.model('messaging-other_message');

		this.$message.input('sentBy').text(this.message.sentBy.fullName);

		let photo = $(util.getProfilePicture(this.message.sentBy)).addClass('user-badge-photo');
		photo.tooltip({ title: this.message.sentBy.fullName, container: 'body' });

		this.$message.find('.badge').append(photo);
	}

	updateStatus(pervious, ownPrevious, ownNext) {
		let message = this.message;

		let lastSent = !!message.id && (!ownNext || !ownNext.id);

		let otherMessagingUsers = this.room.messagingUsers.filter(mU => mU.userId != Ingtech.user.id);

		let lastReadBy = [];
		for (let messagingUser of otherMessagingUsers) {
			if (message.id && moment.utc(message.sentAt).isSameOrBefore(messagingUser.lastReadAt) &&
				(!ownNext || !ownNext.id || moment.utc(ownNext.sentAt).isAfter(messagingUser.lastReadAt))) {

				lastReadBy.push(messagingUser.user);
			}
		}

		this.$message.find('.badge').find('img').remove();

		if (lastReadBy.length > 0) {
			let photo = $(util.getProfilePicture(lastReadBy[0])).addClass('user-badge-photo');
			this.$message.find('.badge').append(photo);

			if (lastReadBy[1]) {
				let photo = $(util.getProfilePicture(lastReadBy[1])).addClass('user-badge-photo second');
				this.$message.find('.badge').append(photo);
			}

			this.$message.input('multiple-read').toggle(!!lastReadBy[2]);

			// this.$message.input('multiple-read').hide();
			// } else {

			// this.$message.input('multiple-read').show();
			this.lastReadByName = util.join(lastReadBy.map(u => u.firstName), ', ', ` ${i18n.__AND} `);
			// }
		}

		this.$message.input('sent').toggle(lastSent && lastReadBy.length == 0);


	}


	static registerFileType(panel, ...fileTypes) {
		for (let fileType of fileTypes) {
			MessagePanel._FILE_PANELS[fileType] = panel;
		}
	}
}

MessagePanel._FILE_PANELS = {};