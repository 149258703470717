

const SUPPORTED_FILE_TYPE = ['txt', 'png', 'jpeg', 'jpg', 'mp3', 'mp4', 'pdf', 'wav'];

class TextBoxPanel {
	constructor(options) {
		this.options = options;

		this.$textBox = util.model('messaging-text_box');
		this.$element = this.$textBox;

		this.$attachments = this.$textBox.find('.attachments-container');

		this.attachments = new Set();

		this.$textBox.input('send').click(() => {
			this.submit();
			this.focus();
		});

		this.$textBox.input('message').keypress(ev => {
			if (ev.key == 'Enter' && !ev.shiftKey) {
				this.submit();

				ev.preventDefault();
			}
		});

		this.$textBox.input('message').on('paste cut keyup keypress change', async () => {
			await util.sleep(0);

			let length = this.$textBox.input('message').val().trim().length;

			this.$textBox.input('char-counter').text(`${length}/${TextBoxPanel.MAX_LENGTH}`);
			this.$textBox.input('char-counter').toggleClass('text-danger', length > TextBoxPanel.MAX_LENGTH);
		});

		this.$textBox.input('important-flag').click(() => {
			this.$textBox.input('important-flag').toggleClass('selected');

			this.focus();
		});


		this.$textBox.input('btn-file').click(() => {
			this.$textBox.input('input-file').click();
		});

		this.$textBox.input('input-file').change(async ev => {
			for (let file of ev.target.files) {
				try {
					await this.addAttachment(file);
				} catch (err) {
					util.notifyError(err);
				}
			}

			this.attachAttachments();
		});


		this.$textBox.input('btn-image').click(() => {
			this.$textBox.input('input-image').click();
		});

		this.$textBox.input('input-image').change(async ev => {
			for (let file of ev.target.files) {
				try {
					await this.addAttachment(file);
				} catch (err) {
					util.notifyError(err);
				}
			}

			this.attachAttachments();
		});
	}

	validate() {
		let message = this.getMessage();

		let length = message.content ? message.content.length : 0;

		if (length == 0) return false;
		if (length > TextBoxPanel.MAX_LENGTH) return false;

		return true;
	}

	submit() {
		if (this.validate()) {
			this.messaging.sendMessage(this.getMessage());

			this.clear();

			// this.messaging.room.scrollBottom();
		} else {
			this.$textBox.input('char-counter').addClass('text-danger');
		}
	}

	/**
	 *
	 *
	 * @param {Messaging} parent
	 * @returns {this}
	 * @memberof TextBox
	 */
	attachTo(parent) {
		this.parent = parent;
		this.messaging = parent.messaging;

		parent.$element.input('text_box-container').append(this.$textBox);

		return this;
	}


	/**
	 *
	 *
	 * @param {File} file
	 * @memberof TextBoxPanel
	 */
	async addAttachment(file) {
		let attachment = {
			fileName: file.name
		};


		if (file.size / (1024 * 1024) > 5) throw new Ingtech.ValidationError('INVALID_FILE_LENGTH', file.name);

		let typeMatch = file.name.match(/^[^\\\/\:\*\?\"<>\|]+\.(\w+)$/);

		if (typeMatch) {
			attachment.fileType = typeMatch[1];
		} else {
			throw new Ingtech.ValidationError('INVALID_FILE_NAME', file.name);
		}

		if (!SUPPORTED_FILE_TYPE.includes(attachment.fileType)) throw new Ingtech.ValidationError('INVALID_FILE_TYPE', file.name);


		let data = await new Promise(resolve => {
			if (file) {
				let reader = new FileReader();
				reader.onloadend = function () {
					resolve(reader.result);
				};
				reader.readAsDataURL(file);
			} else {
				resolve();
			}
		});
		let dataMatch = data ? data.match(/^data:\w+\/\w+;base64,([\s\S]*)/) : null;

		if (dataMatch) {
			attachment.content = dataMatch[1];
		} else {
			throw new Ingtech.ValidationError('INVALID_FILE_DATA', file.name);
		}

		Object.defineProperty(attachment, 'element', { value: new TextBoxAttachmentPanel(attachment, this) });

		this.attachments.add(attachment);
	}

	removeAttachment(attachment) {
		this.attachments.delete(attachment);

		this.attachAttachments();
	}

	attachAttachments() {
		this.messaging.activeRoom.freezeScroll(() => {
			this.$attachments.children().detach();
	
			for (let attachment of this.attachments) {
				attachment.element.appendTo(this.$attachments);
			}
		});
	}

	show() {
		this.$element.show();
		this.focus();

		return this;
	}

	hide() {
		this.$element.hide();

		return this;
	}

	getMessage() {
		return {
			content: this.$textBox.input('message').val().trim() || null,
			sentAt: new Date().toJSON(),
			sentById: Ingtech.user.id,
			unsent: true,
			priority: this.$textBox.input('important-flag').hasClass('selected') ? 1 : 0,
			attachments: [...this.attachments]
		};
	}

	updateStatus(lastMessageReceived) {
		this.$textBox.find('.status-text').text(lastMessageReceived ? i18n.__LAST_MESSAGE + ' ' + moment.utc(lastMessageReceived.sentAt).local().format('dddd DD MMM HH:mm') : '');
	}

	focus() {
		this.$textBox.input('message').focus();
	}

	clear() {
		this.$textBox.input('message').val('').change();

		this.$textBox.input('important-flag').removeClass('selected');

		this.attachments.clear();
		this.attachAttachments();
	}
}

TextBoxPanel.MAX_LENGTH = 1000;