
/**
 * @typedef {Object} MessagingBroker.Message
 * @property {string} content
 * @property {number} id
 * @property {number} messagingRoomId
 * @property {number} priority
 * @property {string} sentAt
 * @property {number} sentById
 * 
 */

/**
 * @typedef {Object} MessagingBroker.MessageBody
 */


/**
 * @typedef {Object} MessagingBroker.Room
 * @property {number} id
 * @property {Object[]} messagingUsers
 * @property {MessagingBroker.Message} lastSentMessagingMessage
 * @property {MessagingBroker.Message[]} messagingMessages
 */

/**
 * @typedef {Object} MessagingBroker.RoomBody
 * @property {number[]} usersId
 */

/**
 * @typedef {Object} MessagingBroker.RoomPreview
 * @property {number} id
 * @property {Object[]} messagingUsers
 * @property {MessagingBroker.Message} lastSentMessagingMessage
 */


class MessagingBroker extends EventEmitter {
	/**
	 *
	 *
	 * @static
	 * @returns {MessagingBroker}
	 * @memberof MessagingBroker
	 */
	static getInstance() {
		if (!this.instance) new this();

		return this.instance;
	}

	constructor() {
		super();

		if (this.constructor.instance) throw new Error('MessagingBroker is already instantiated.');

		this.constructor.instance = this;
		this.broker = Ingtech.Broker.scope('messaging');
		this.userBroker = Ingtech.Broker.scope('user');
	}

	// GETTERS

	/**
	 *
	 * @returns {Promise<Room[]>}
	 * @memberof MessagingBroker
	 */
	async getRoomsPreviews(messaging) {
		let messagingRooms = await $.get('/json/messaging/rooms', { limit: 20 });

		return messagingRooms.map(room => new Room(room, messaging));
	}


	async getRoomMessagesFrom(roomId, limit = 20, from = null) {
		let query = { roomId, limit };
		if (from) query.from = moment.utc(from).toISOString();

		let messagingMessages = await $.get('/json/messaging/messages', query);

		return messagingMessages;
	}

	/**
	 *
	 * @param {number} roomId
	 * @returns {Promise<MessagingBroker.Room>}
	 * @memberof MessagingBroker
	 */
	async getRoom(roomId, messaging) {
		let room = await $.get(`/json/messaging/room/${roomId}`);

		return new Room(room, messaging);
	}


	async getUsers() {
		let users = await $.get('/json/messaging/users');
		let filteredUsers = users.filter(user => user.id !== Ingtech.user.id);

		return filteredUsers;
	}


	// SENDERS

	/**
	 *
	 *
	 * @param {MessagingBroker.MessageBody} message
	 * @returns {Promise<boolean>}
	 * @memberof MessagingBroker
	 */
	async newMessage(message) {
		let messageBody = {
			messagingRoomId: message.messagingRoomId,
			content: message.content,
			sentAt: message.sentAt,
			priority: message.priority,
			messagingAttachments: message.attachments
		};

		let res = await this.broker.emit('add-message', messageBody);

		return res;
	}


	/**
	 *
	 *
	 * @returns {Promise<boolean>}
	 * @memberof MessagingBroker
	 */
	async updateLastRead(room) {
		let res = await this.broker.emit('update-last-read', { messagingRoomId: room.id, date: moment.utc().format() });

		return res;
	}


	/**
	 *
	 *
	 * @param {MessagingBroker.RoomBody} room
	 * @returns {Promise<boolean>}
	 * @memberof MessagingBroker
	 */
	async createRoom(room) {
		let res = await this.broker.emit('create-room', room);

		return res;
	}


	async addUsers(room, usersId) {
		let res = await this.broker.emit('add-users', {
			messagingRoomId: room.id,
			usersId
		});

		return res;
	}


	// LISTENERS

	/**
	 * @callback messageAddedCallback
	 * @param {MessagingBroker.Message} message
	 * @returns {void}
	 * 
	 *
	 * @param {messageAddedCallback} listener
	 * @returns {this}
	 * @memberof MessagingBroker
	 */
	onMessageAdded(listener) {
		this.broker.on('message-added', message => {
			let messagingMessage = message.body.messagingMessage;
			messagingMessage.transactionId = message.parentTransactionId;

			return listener(messagingMessage);
		});

		return this;
	}




	/**
	 * @callback roomCreatedCallback
	 * @param {MessagingBroker.Room} room
	 * @param {string} transactionId
	 * @returns {void}
	 * 
	 *
	 * @param {roomCreatedCallback} listener
	 * @returns {this}
	 * @memberof MessagingBroker
	 */
	onRoomCreated(listener, messaging) {
		this.broker.on('room-created', message => {
			let messagingRoom = message.body.messagingRoom;
			messagingRoom.transactionId = message.parentTransactionId;

			return listener(new Room(messagingRoom, messaging));
		});
	}



	/**
	 * @callback roomEditedCallback
	 * @param {MessagingBroker.Room} room
	 * @returns {void}
	 * 
	 *
	 * @param {roomEditedCallback} listener
	 * @returns {this}
	 * @memberof MessagingBroker
	 */
	onRoomUpdated(listener) {
		this.broker.on('room-updated', message => {
			let messagingRoom = message.body.messagingRoom;

			return listener(messagingRoom);
		});
	}


	onRoomRead(listener) {
		this.broker.on('room-read', message => {
			let messagingUser = message.body.messagingUser;

			return listener(messagingUser);
		});
	}



	onUserConnectionUpdated(listener) {
		this.userBroker.on('connection-updated', message => {
			let userConnection = message.body.userConnection;

			return listener(userConnection);
		});
	}


	onUserHosUpdated(listener) {
		this.userBroker.on('hos-updated', message => {
			let eventRecord = message.body.eventRecord;

			return listener(eventRecord);
		});
	}
}