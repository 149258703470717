class WelcomePanel {
	constructor(options) {
		this.options = options;

		this.$welcome = util.model('messaging-welcome');
		this.$element = this.$welcome;
	}

	/**
	 *
	 *
	 * @param {Messaging} parent
	 * @returns {this}
	 * @memberof Welcome
	 */
	attachTo(parent) {
		this.parent = parent;
		this.messaging = parent.messaging;

		let $position = parent.$element.input('welcome-container');
		$position.after(this.$welcome);
		$position.remove();

		return this;
	}

	show() {
		this.$element.show();

		return this;
	}

	hide() {
		this.$element.hide();

		return this;
	}
}