class TextBoxAttachmentPanel {
	constructor(attachment, textBox) {
		this.$attachment = util.model('messaging-text_box-attachment');
		
		// this.$attachment.input('image').attr('src', `/detail/content/${detail.id}/${detail.content}`);
		// this.$attachment.input('image').on('load', async () => {
		// 	message.element.roomPanel.freezeScroll(() => {
		// 		this.$attachment.input('image').show();
		// 	});
		// });
		
		this.$attachment.input('name').text(attachment.fileName);

		this.$attachment.input('delete').click(() => {
			textBox.removeAttachment(attachment);
		});

		this.$attachment.input('name').tooltip({
			title: attachment.fileName,
			container: 'body'
		});

		

		let icon = 'icon-main-document';
		switch (attachment.fileType) {
			case 'pdf':
				icon = 'icon-main-pdf';
				break;
			case 'wav':
				icon = 'icon-main-audio-file';
				break;
			case 'png':
			case 'jpeg':
			case 'jpg':
				icon = 'icon-main-image-file';
				break;

			default:
				break;
		}

		this.$attachment.input('icon').addClass(icon);
	}

	appendTo($element) {
		$element.append(this.$attachment);
	}
}