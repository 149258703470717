class Message {
	setRoom(room) {
		Object.defineProperty(this, 'room', { value: room });

		
		if (!this.sentBy) {
			let messagingUser = this.room.messagingUsers.find(messagingUser => messagingUser.userId == this.sentById);
			let user = messagingUser ? messagingUser.user : null;

			this.sentBy = user;
		}
		
		this.ownMessage = !this.sentBy || this.sentById == Ingtech.user.id;
	}

	update(changes) {
		let attachmentsCount = this.messagingAttachments ? this.messagingAttachments.length : 0;

		Object.assign(this, changes);

		if (this._element && changes.messagingAttachments && changes.messagingAttachments.length != attachmentsCount) {
			this._element.setAttachments(this.messagingAttachments);
		}

		delete this.transactionId;
		delete this.unsent;
	}

	get element() {
		if (!this._element) {
			Object.defineProperty(this, '_element', { value: new MessagePanel(this, this.room) });
		}

		return this._element;
	}
}