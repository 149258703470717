class UserBannerPanel {
	constructor(user) {
		this.$userBanner = util.model('messaging-user_banner');
		this.$element = this.$userBanner;

		this.$userBanner.input('fullName').text(user.fullName);
		this.$userBanner.attr('data-user-id', user.id);
		
		new UserBadgePanel(user).appendTo(this.$userBanner.input('badge'));
	}

	appendTo($element) {
		$element.append(this.$userBanner);
	}
}