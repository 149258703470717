class UserSelectionPanel {
	constructor(options) {
		this.options = options;

		this.$userSelection = util.model('messaging-user_selection');
		this.$element = this.$userSelection;

		this.room = new RoomPanel({ hideHeader: true });

		this.$list = this.$userSelection.input('users_list-container');
		this.search = '';

		this.$userSelection.input('save').click(() => {
			let newUsersId = this.getSelectedUsers();

			if (newUsersId.length > 0) {
				this.messaging.editRoom(this.editingRoom, this.getSelectedUsers(true), newUsersId);
			}
		});

		this.$userSelection.input('cancel').click(() => {
			if (this.editingRoom) {
				this.messaging.openRoom(this.editingRoom);
			}
		});

		this.$userSelection.input('return-msg-menu').click(() => {
		// 	//Set a new class here
			this.messaging.openWelcome();
		});
	}

	/**
	 *
	 *
	 * @param {Messaging} parent
	 * @returns {this}
	 * @memberof UserSelection
	 */
	attachTo(parent) {
		this.parent = parent;
		this.messaging = parent.messaging;

		let $position = parent.$element.input('user_selection-container');
		$position.after(this.$userSelection);
		$position.remove();

		this.room.attachTo(this);

		this.populate();

		return this;
	}

	async populate() {
		this.clear();


		this.users = await this.messaging.getUsers();
		for (let user of this.users) {
			user.banner = new UserBannerPanel(user); // .appendTo(this.$list);
		}

		this.attachUsers();

		let $select = this.$userSelection.input('usersId');

		$select.select2({
			data: this.users.map(user => ({
				id: user.id,
				text: user.fullName
			})),
			tokenSeparators: [','],
			tags: false,
			multiple: true
		}).on('select2:opening', ev => {

			ev.preventDefault();
			ev.stopPropagation();
		}).on('change', ev => {
			this.usersChanged();
		});

		this.$list.on('click', '.panel-user', ev => {
			this.$userSelection.find('.select2-search__field').val('').change();
			this.search = '';

			this.$list.find('.selected').removeClass('selected');

			let $selected = $(ev.currentTarget);

			let usersId = $select.val() || [];
			$select.val(usersId.concat([$selected.attr('data-user-id')])).change();

		});

		let select = $select.data('select2');

		select.$selection
			.on('keydown', '.select2-search__field', ev => {
				let key = ev.key;

				let $selected = this.$list.find('.selected');
				let $input = $(ev.target);

				if (['ArrowUp', 'ArrowDown', 'Enter'].includes(key)) {
					switch (key) {
						case 'ArrowUp':
							let $prev = $selected.prev();

							if ($prev.length) {
								$selected.removeClass('selected');
								$prev.addClass('selected');
							}

							break;
						case 'ArrowDown':
							if ($selected.length == 0) {
								$(this.$list.children().get(0)).addClass('selected');
							} else {
								let $next = $selected.next();

								if ($next.length) {
									$selected.removeClass('selected');
									$next.addClass('selected');
								}
							}

							break;
						case 'Enter':
							if ($selected.length != 0) {
								$selected.removeClass('selected');
								$input.val('').change();
								this.search = '';

								let usersId = $select.val() || [];
								$select.val(usersId.concat([$selected.attr('data-user-id')])).change();

							}

							break;

						default:
							break;
					}


					ev.preventDefault();

					return;
				}

				if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
					$selected.removeClass('selected');
				} else {
				}

			}).on('keyup', '.select2-search__field', ev => {
				let $input = $(ev.target);

				if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(ev.key)) {
					this.search = $input.val();

					this.attachUsers();
				}
			});

		let lastClickedElement;
		$(window).mousedown(ev => {
			lastClickedElement = $(ev.target);
		});

		select.on('blur', async ev => {
			if (lastClickedElement.closest(this.$list).length == 0) {
				this.closeList();
			}
		});


		select.on('focus', () => {
			this.openList();
		});
	}

	usersChanged() {
		let $select = this.$userSelection.input('usersId');
		let select = $select.data('select2');

		this.attachUsers();

		select.trigger('blur');
		select.$selection.find('.select2-search__field').blur();

		let users = this.getSelectedUsers();

		let title, participants;
		if (this.editingRoom) {
			let messagingUsers = this.editingRoom.messagingUsers.filter(mU => mU.userId != Ingtech.user.id);

			title = messagingUsers.map(mU => mU.user.fullName).join(', ');
			participants = i18n._$PARTICIPANT_COUNT(messagingUsers.length + users.length + 1);
		} else {
			if (users.length > 0) {
				title = i18n.__NEW_MESSAGE;
				participants = i18n._$PARTICIPANT_COUNT(users.length + 1);
			} else {
				title = i18n.__NEW_MESSAGE;
				participants = '';
			}
		}

		this.$element.find('.conversation-title').text(title);
		this.$element.find('.conversation-participants').text(participants);

		this.findRoom();

		this.closeList();
	}

	attachUsers() {
		this.clear();

		let usersId = this.$userSelection.input('usersId').val() || [];

		let filteredUsers = util.searchIn(this.users, this.search, ['username', 'fullName', 'email'])
			.filter(user => {
				return !usersId.includes(user.id.toString()) && (!this.editingRoom || !this.editingRoom.messagingUsers.some(mU => mU.userId == user.id));
			})
			.sort((a, b) => a.fullName.localeCompare(b.fullName));

		for (let user of filteredUsers) {
			user.banner.appendTo(this.$list);
		}
	}

	clear() {
		this.$list.html('');
	}

	openForNewRoom() {
		this.$userSelection.find('.select2-search__field').val('').change();
		this.search = '';

		this.$userSelection.input('usersId').val([]).change();


		this.matchingRoom = null;

		util.sleep(0).then(() => {
			this.$userSelection.find('.select2-search__field').focus();
		});

		this.$userSelection.find('.conversation-control').hide();

		this.show();
	}

	openForEditingRoom(room) {
		this.editingRoom = room;

		let usersId = room.messagingUsers.filter(mU => mU.userId != Ingtech.user.id).map(mU => mU.userId);
		this.$userSelection.find('.select2-search__field').val('').change();
		this.search = '';

		this.$userSelection.input('usersId').val([]).change();

		this.matchingRoom = null;

		util.sleep(0).then(() => {
			this.$userSelection.find('.select2-search__field').focus();
		});

		this.$userSelection.find('.conversation-control').show();

		this.show();
	}

	openList() {
		this.room.hide();


		this.$list.show();
		this.$list.scrollTop(0);
	}

	closeList() {
		if (this.editingRoom) return;

		this.room.show();

		this.$list.hide();
	}

	show() {
		this.$element.show();

		return this;
	}

	hide() {
		this.$element.hide();

		this.matchingRoom = null;
		this.editingRoom = null;

		return this;
	}

	findRoom() {
		let usersId = this.getSelectedUsersId(true).concat([Ingtech.user.id]);

		this.matchingRoom = this.messaging.roomsList.findRoom(usersId);
		this.room.setRoom(this.matchingRoom);
	}

	getSelectedUsersId(includeRoom = false) {
		let usersId = (this.$userSelection.input('usersId').val() || []).map(id => parseInt(id));

		if (includeRoom && this.editingRoom) {
			usersId.push(...this.editingRoom.messagingUsers.filter(mU => mU.userId != Ingtech.user.id).map(mU => mU.userId));
		}

		return usersId;
	}

	getSelectedUsers(includeRoom = false) {
		let usersId = this.getSelectedUsersId(includeRoom);

		return this.users.filter(user => usersId.includes(user.id));
	}
}
