/**
 * @typedef {Object} Messaging.options
 * @property {*} container
 */

class Messaging extends EventEmitter {
	/**
	 *Creates an instance of Messaging.
	 * @param {Messaging.options} options
	 * @memberof Messaging
	 */
	constructor(options = {}) {
		super();

		this.options = options;

		this.messaging = this;

		this.attachBroker();
		this.initializeFrame();

		this.state = 'welcome';

		this.syncUsers();

		if (options.container) {
			this.appendTo(element);
		}

		this.emit('change-state', this.state);

		if (window.location.pathname.includes('messaging')) {
			$('.ui').addClass("height-fix");
		}
		// console.log(window.location.pathname.includes('messaging'))



		Ingtech.Notification.on('click-messaging', notification => {
			let room = this.rooms.find(room => room.id == notification.additionalInformations.messagingRoomId);

			if (room) this.openRoom(room);

			return true;
		});


		let wasInPhoneMode = this.isInPhoneMode();
		$(window).resize(() => {
			let isInPhoneMode = this.isInPhoneMode();

			if (isInPhoneMode != wasInPhoneMode) {
				this.emit('switch-view-mode', isInPhoneMode ? 'phone' : 'desktop');
			}

			wasInPhoneMode = isInPhoneMode;
		});
	}

	initializeFrame() {
		this.$frame = util.model('messaging-frame');
		this.$element = this.$frame;

		this.roomsList = new RoomsListPanel(this.options).attachTo(this);
		this.room = new RoomPanel(this.options).attachTo(this).hide();
		this.activeRoom = this.room;
		this.userSelection = new UserSelectionPanel(this.options).attachTo(this).hide();
		this.welcome = new WelcomePanel(this.options).attachTo(this);

		this.textBox = new TextBoxPanel(this.options).attachTo(this).hide();

		this.$frame.find('[title]').tooltip();
	}


	appendTo(element) {
		$(element).append(this.$frame);
	}

	attachBroker() {
		this.broker = MessagingBroker.getInstance();


		this.broker.onRoomCreated(room => {
			this.insertRoom(room);
		}, this);


		this.broker.onMessageAdded(message => {
			let room = this.rooms.find(room => room.id == message.messagingRoomId);

			room.receiveMessage(message);
		}, this);


		this.broker.onRoomRead(messagingUser => {
			let room = this.rooms.find(room => room.id == messagingUser.messagingRoomId);

			room.getRead(messagingUser);
		}, this);


		this.broker.onRoomUpdated(changes => {
			let room = this.rooms.find(room => room.id == changes.id);

			room.update(changes);
		});


		this.broker.onUserConnectionUpdated(userConnection => {
			let user = this.users.find(user => user.id == userConnection.userId);

			if (user) {
				user.userConnection = userConnection;

				for (let userBadge of user.badges || []) {
					userBadge.updateStatus();
				}
			}
		});


		this.broker.onUserHosUpdated(eventRecord => {
			let user = this.users.find(user => user.id == eventRecord.userId);

			if (user) {
				user.latestEventRecord = eventRecord;

				for (let userBadge of user.badges || []) {
					userBadge.updateStatus();
				}
			}
		});
	}


	async syncRooms() {
		try {
			await this.getUsers();
			this.rooms = await this.broker.getRoomsPreviews(this);

			this.roomsList.setRooms(this.rooms);
		} catch (err) {
			console.error(err);
			this.roomsList.displayError(err);
		}
	}

	openWelcome(silent) {
		this.state = 'welcome';

		$('.msg-main-panel').removeClass("msg-hidden");
		$('.msg-side-panel').removeClass("msg-active");

		this.closeRoom();
		this.welcome.show();
		this.textBox.hide();
		this.userSelection.hide();

		if (!silent) this.emit('change-state', this.state);
	}

	newRoomMenu() {
		this.userSelection.openForNewRoom();

		this.state = 'newRoom';

		$('.msg-main-panel').addClass("msg-hidden");
		$('.msg-side-panel').addClass("msg-active");

		this.closeRoom();
		this.welcome.hide();
		this.textBox.show();

		this.emit('change-state', this.state);
	}

	editRoomMenu(room) {
		this.userSelection.openForEditingRoom(room);

		this.state = 'editRoom';

		$('.msg-main-panel').addClass("msg-hidden");
		$('.msg-side-panel').addClass("msg-active");

		this.closeRoom();

		this.roomsList.selectRoom(room);
		this.welcome.hide();
		this.textBox.hide();

		this.activeRoom = this.userSelection.room;

		this.emit('change-state', this.state, room);
	}

	openRoom(room) {
		if (room == this.selectedRoom) return;

		this.state = 'room';

		this.selectedRoom = room;

		// room.banner.selected(true);

		$('.msg-main-panel').addClass("msg-hidden");
		$('.msg-side-panel').addClass("msg-active");

		this.roomsList.selectRoom(room);

		this.room.open(room);
		this.welcome.hide();
		this.textBox.show();
		this.userSelection.hide();

		this.activeRoom = this.room;

		this.emit('change-state', this.state, room);
	}

	closeRoom() {
		this.room.hide();
		this.room.clearMessage();
		this.roomsList.unselectRoom();

		this.selectedRoom = null;
	}

	async createRoom(users, message) {
		let now = moment.utc().format();

		if (!users || users.length < 1) return;

		let roomBody = {
			usersId: users.map(user => user.id)
		};

		let tempRoom = new Room({
			createdAt: now,
			createdBy: Ingtech.user.id,
			oneToOne: users.length == 1,
			messagingUsers: [...users, Ingtech.user].map(user => ({
				user: user,
				userId: user.id,
				joinedAt: now,
				lastReadAt: now,
				unreadMessagingMessage: 0,
				seePreJoinMessage: true
			}))
		}, this);


		let room = this.insertRoom(tempRoom);

		this.broker.createRoom(roomBody).then(response => {
			room.transactionId = response.transactionId;
		});

		this.openRoom(room);
		if (message) this.sendMessage(message);
		// this.room.addMessage(message);
		// this.room.scrollBottom();
	}

	async editRoom(room, users, newUsers) {
		let matchingRoom = this.userSelection.matchingRoom;

		if (matchingRoom) {
			this.openRoom(matchingRoom);
		} else {
			if (room.messagingUsers.length <= 2) {
				this.createRoom(users);
			} else {
				this.broker.addUsers(room, newUsers.map(u => u.id));

				room.addUsers(newUsers);
				this.openRoom(room);
			}
		}
	}


	async sendMessage(message) {
		switch (this.state) {
			case 'newRoom':
				let matchingRoom = this.userSelection.matchingRoom;

				if (matchingRoom) {
					this.openRoom(matchingRoom);
					this.sendMessage(message);
				} else {
					this.createRoom(this.userSelection.getSelectedUsers(), message);
				}

				break;
			case 'room':
				this.submitMessage(this.selectedRoom, message);

				break;
			default:
				break;
		}
	}

	/**
	 *
	 *
	 * @param {Room} room
	 * @param {*} message
	 * @memberof Messaging
	 */
	submitMessage(room, message) {
		room.sendMessage(message);

		if (room.id) {
			this.publishMessage(room, message);
		} else {
			room.bufferedMessagingMessages = room.bufferedMessagingMessages || [];
			room.bufferedMessagingMessages.push(message);
		}
	}

	publishMessage(room, messages) {
		messages = messages instanceof Array ? messages : [messages];

		for (let message of messages) {
			message.messagingRoomId = room.id;

			this.broker.newMessage(message).then(response => {
				message.transactionId = response.transactionId;
			});
		}
	}



	newMessage(room, message) {
		if (room == this.selectedRoom) this.room.newMessage(message);
		if (room == this.userSelection.matchingRoom) this.userSelection.room.newMessage(message);
	}

	messagesUpdated(room) {
		if (room == this.selectedRoom) this.room.updateMessagesStatus();
		if (room == this.userSelection.matchingRoom) this.userSelection.room.updateMessagesStatus();
	}

	/**
	 *
	 *
	 * @param {Room} room
	 * @memberof Messaging
	 */
	roomUpdated(room) {
		if (room == this.selectedRoom) this.room.updateHeader();
		if (room.banner) room.banner.populate();
	}

	/**
	 *
	 *
	 * @param {Room} room
	 * @memberof Messaging
	 */
	roomUpdatedStatus(room) {
		this.roomsList.updateRoomMessage(room);
	}

	insertRoom(room) {
		let matchingRoom = this.rooms.find(mR => (room.id && room.id == mR.id) || (room.transactionId && room.transactionId == mR.transactionId));

		if (matchingRoom) {
			matchingRoom.update(room);

			return matchingRoom;
		} else {
			let newRoom = new Room(room, this);

			this.rooms.push(newRoom);
			this.roomsList.addRooms(newRoom);

			return newRoom;
		}
	}



	async syncUsers() {
		this.users = await this.broker.getUsers();

		for (let cb of this._getUsersCb || []) {
			cb(this.users);
		}

		return this.users;
	}

	async getUsers() {
		return this.users || new Promise(resolve => (this._getUsersCb = this._getUsersCb || [], this._getUsersCb.push(resolve)));
	}

	isInPhoneMode() {
		return $(document).width() <= 767;
	}
}

Ingtech.Messaging = Messaging;