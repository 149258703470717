class RoomPanel extends EventEmitter {
	constructor(options) {
		super();

		this.options = options;

		this.$room = util.model('messaging-room');
		this.$element = this.$room;

		this.$list = this.$room.find('.conversation-body');

		this.isFetchingMessages = false;


		this.$list.scroll(async ev => {
			this.$list.find('[title]').tooltip('hide');

			let scrollTop = this.$list.scrollTop();
			let scrollBottom = this.$list.scrollBottom();

			if (scrollTop <= 500 && !this.isFetchingMessages && !this.atTheTop) {
				await this.attachMessages(false, true);
			}


			if (scrollBottom <= 500 && !this.atTheBottom) {
				await this.attachMessages(false, false);
			}
		});


		this.$room.input('add-user').click(() => {
			if (this.room) this.messaging.editRoomMenu(this.room);
		});
		// this.room.messages = [];

		this.$room.input('return-msg-menu').click(() => {
			this.messaging.openWelcome();
		});


		Ingtech.Notification.on('new-messaging', notification => {
			if (this.room && notification.sourceReference == this.room.id) {
				return true;
			}
		});

		page.on('focus', () => {
			if (this.room) this.room.read();
		});
	}

	/**
	 *
	 *
	 * @param {Messaging} parent
	 * @returns {this}
	 * @memberof Room
	 */
	attachTo(parent) {
		this.parent = parent;
		this.messaging = parent.messaging;

		let $position = parent.$element.input('room-container');

		if (this.options.hideHeader) {
			$position.after(this.$list);
		} else {
			$position.after(this.$room);
		}

		$position.remove();

		return this;
	}

	detach() {
		this.$element.after('<div class="fill-height" name="room-container">');
		this.$element.detach();
	}

	show() {
		this.$element.show();
		this.$list.show();

		this.scrollBottom();

		return this;
	}

	hide() {
		this.room = null;

		this.$element.hide();
		this.$list.hide();

		return this;
	}

	/**
	 *
	 *
	 * @param {Room} room
	 * @memberof RoomPanel
	 */
	open(room) {
		this.clearMessage();
		this.setRoom(room);

		this.show();
	}

	/**
	 *
	 *
	 * @param {Room} room
	 * @memberof RoomPanel
	 */
	setRoom(room) {
		this.room = room;

		if (room) {
			this.room.messages = this.room.messages || [];
			this.room.messagesLoaded = false;

			this.attachMessages(true);
		}

		this.updateHeader();

		this.scrollBottom();

		if (room) {
			this.loadMessage();
		}
	}

	updateHeader() {
		this.$room.find('.conversation-header').toggle(!!this.room && !this.options.hideHeader);

		if (this.room && !this.options.hideHeader) {
			this.displayName = util.join(this.room.messagingUsers.filter(messagingUser => messagingUser.userId != Ingtech.user.id).map(messagingUser => messagingUser.user.fullName), ', ', ` ${i18n.__AND} `);
			this.$room.find('.conversation-participants').text(i18n._$PARTICIPANT_COUNT(this.room.messagingUsers.length));

			this.$room.find('.conversation-title').text(this.displayName);
		}
	}

	async loadMessage(limit = RoomPanel.VIEW_LENGTH, from = null) {
		if (!this.room) return;

		this.isFetchingMessages = true;

		let roomId = this.room.id;
		let messages = roomId ? await Promise.race([
			this.messaging.broker.getRoomMessagesFrom(roomId, limit, from),
			new Promise(resolve => this.once('clear', () => resolve(false)))
		]) : [];

		this.isFetchingMessages = false;


		if (messages === false) {
			return;
		}


		if (!this.room) return;

		if (limit != messages.length) this.room.allMessageLoaded = true;

		this.room.insertMessages(messages);

		this.room.read();
	}

	async loadPreviousMessages() {
		await this.loadMessage(RoomPanel.VIEW_LENGTH * 10, this.room.messages[0].sentAt);
	}


	clearMessage(refresh = true) {
		this.room = null;
		this.displayedMessages = [];
		
		if (refresh) {
			this.attachMessages();
		}

		this.emit('clear');
	}

	getAnchor(top = false) {
		let freezeTo, freezeDelta, tempPos = 0;
		let roomPos = this.$list.scrollTop() + (top ? 0 : this.$list.innerHeight());
		for (let message of this.displayedMessages || []) {
			let messageHeight = message.element.$message.outerHeight();

			if ((tempPos + messageHeight) > roomPos) {
				freezeTo = message;
				freezeDelta = roomPos - tempPos;

				return [freezeTo, freezeDelta];
			}

			tempPos += messageHeight;
		}

		return [];
	}

	moveToAnchor(freezeTo, freezeDelta) {
		if (!freezeTo) {
			this.scrollBottom();
		} else {
			let tempPos = 0;

			for (let message of this.displayedMessages || []) {
				if (message == freezeTo) {
					this.$list.scrollTop(tempPos + freezeDelta - this.$list.innerHeight());

					break;
				}

				tempPos += message.element.$message.outerHeight();
			}
		}
	}

	moveView(bottom) {
		if (!this.room) return;

		let messages = this.room && this.room.messages || [];

		if (bottom) {
			this.displayedMessages = messages.slice(Math.max(0, messages.length - RoomPanel.VIEW_LENGTH), messages.length);
		}

		let anchorPos = bottom ? messages.length : messages.indexOf(this.getAnchor(true)[0]);
		if (anchorPos == -1) anchorPos = messages.length;

		this.atTheTop = anchorPos - RoomPanel.VIEW_LENGTH < 0 && this.room.allMessageLoaded;
		this.atTheBottom = anchorPos + RoomPanel.VIEW_LENGTH > messages.length;

		if (anchorPos - RoomPanel.VIEW_LENGTH < 0 && this.room && !this.room.allMessageLoaded && this.room.messages.length >= RoomPanel.VIEW_LENGTH) {
			this.loadPreviousMessages().then(() => {
				this.attachMessages();
			});

			return true;
		}

		this.displayedMessages = messages.slice(Math.max(0, anchorPos - RoomPanel.VIEW_LENGTH), Math.min(anchorPos + RoomPanel.VIEW_LENGTH, messages.length));
	}

	attachMessages(bottom) {
		this.freezeScroll(() => {
			if (this.moveView(bottom) === true) return;


			this.$list.children().detach();

			for (let message of this.displayedMessages) {
				message.element.appendTo(this.$list, this);
			}
		});
	}

	updateMessagesStatus() {
		let messages = this.room.messages;
		let ownMessages = messages.filter(message => message.ownMessage);

		for (let i = 1; i <= ownMessages.length; i++) {
			let j = ownMessages.length - i;
			let message = ownMessages[j];
			let ownNext = ownMessages[j + 1];
			let ownPrevious = ownMessages[j - 1];
			let pervious = messages[messages.indexOf(message) - 1];

			if (message._element && message._element.$message.is(':visible')) {
				let stop = message.element.updateStatus(pervious, ownPrevious, ownNext);
				if (stop) break;
			}
		}

		let lastMessageReceived;
		for (let i = 1; i <= messages.length; i++) {
			let message = messages[messages.length - i];

			if (message.sentById != Ingtech.user.id) {
				lastMessageReceived = message;
				break;
			}
		}

		this.messaging.textBox.updateStatus(lastMessageReceived);
	}

	scrollBottom() {
		if (!this.$list.is(':visible')) return;

		this.$list.scrollBottom(0);
		this.$list.parent().scrollBottom(0);
	}

	freezeScroll(cb) {
		// if (!this.room) return;

		util.disableScroll();
		let [freezeTo, freezeDelta] = this.getAnchor();

		cb();

		this.moveToAnchor(freezeTo, freezeDelta);
		util.enableScroll();
	}

	newMessage() {
		this.attachMessages(true);
		this.scrollBottom();

		this.room.read();
	}
}

RoomPanel.VIEW_LENGTH = 20;