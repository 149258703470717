class UserBadgePanel {
	constructor(user) {
		if (!user) return;

		this.$userBadge = util.model('messaging-user_badge');
		this.$element = this.$userBadge;
		
		this.$picture = $(util.getProfilePicture(user)).addClass('user-badge-photo');
		this.$userBadge.input('picture').replaceWith(this.$picture);

		this.$status = this.$userBadge.input('status');

		this.user = user;

		user.badges = user.badges || [];
		user.badges.push(this);

		this.updateStatus();
	}

	appendTo($element) {
		$element.replaceWith(this.$userBadge);

		return this;
	}

	updateStatus() {
		let online = !!this.user.userConnection && this.user.userConnection.status == 1;
		
		this.$status.toggleClass('stat1', online);

		let eventCode = this.user.latestEventRecord ? this.user.latestEventRecord.eventCode : null;
		this.$picture.attr('data-hos', eventCode);
	}

	addUser(user) {
		this.secondBadge = new UserBadgePanel(user);
		this.secondBadge.$userBadge.children().addClass('second');
		this.$userBadge.append(this.secondBadge.$userBadge.children());
	}
}