class RoomBannerPanel {
	constructor(room) {
		this.$roomBanner = util.model('messaging-room_banner');
		this.$element = this.$roomBanner;

		this.room = room;

		this.status = 0;
		this.unreadMessages = 0;

		this.populate();

		// this.$roomBanner.input('fullName').text(room.fullName);
		// this.$roomBanner.attr('data-room-id', room.id);
	}

	populate() {
		let users = this.room.messagingUsers.filter(messagingUser => messagingUser.userId != Ingtech.user.id).map(({ user }) => user);
		let names = users.map(({ fullName }) => fullName);

		let namesToDisplay = names.splice(0, 2);
		if (names.length > 0) namesToDisplay.push('+' + names.length);
		this.displayName = namesToDisplay.join(', ');

		this.$roomBanner.input('title').text(this.displayName);

		this.updateStatus();

		this.$roomBanner.input('unread-message').tooltip({
			title: () => {
				if (this.status == 2) {
					return i18n.__IMPORTANT_MESSAGE;
				} else if (this.status == 1) {
					if (this.unreadMessages > 0) {
						return i18n._$UNREAD_MESSAGES_COUNT(this.unreadMessages);
					} else {
						return i18n.__NEW_MESSAGING_ROOM;
					}
				} else {
					return i18n.__NO_UNREAD_MESSAGE;
				}
			},
			container: 'body'
		});


		let badge = new UserBadgePanel(users[0]).appendTo(this.$roomBanner.find('.badges'));
		if (users[1]) badge.addUser(users[1]);
	}

	updateStatus() {
		let messageContent = '';
		let messageDate = '';

		if (this.room.lastSentMessagingMessage) {
			let message = this.room.lastSentMessagingMessage;

			messageDate = moment.utc(message.sentAt).local().format('dddd DD MMM HH:mm');

			if (message.sentById == Ingtech.user.id) {
				messageContent = `${i18n.__ME}: ${message.content}`;
			} else {
				if (this.room.messagingUsers.length <= 2) {
					messageContent = message.content;
				} else {
					let { user } = this.room.messagingUsers.find(messagingUser => messagingUser.userId == message.sentById);

					messageContent = `${user.firstName}: ${message.content}`;
				}
			}

			this.$roomBanner.input('important-flag').toggle(message.priority == 1);
			this.$roomBanner.input('attachment').toggle(!!message.messagingAttachments && message.messagingAttachments.length > 0);
		}

		this.$roomBanner.input('last-message-content').text(messageContent);
		this.$roomBanner.input('last-message-date').text(messageDate);


		let messagingUser = this.room.messagingUsers.find(mU => mU.userId == Ingtech.user.id);
		let dot = this.$roomBanner.input('unread-message').removeClass();

		let relatedNotifications = Ingtech.Notification.notifications.filter(notification =>
			notification.status == Ingtech.Notification.STATUS.UNSEEN &&
			notification.type == Ingtech.Notification.TYPE.MESSAGING &&
			notification.additionalInformations.messagingRoomId == messagingUser.messagingRoomId);

		this.unreadMessages = Math.max(messagingUser.unreadMessages, relatedNotifications.length);

		if (messagingUser.unreadImportantMessages || relatedNotifications.some(notification => notification.priority > 0))
			this.status = 2;
		else if (this.unreadMessages || (!messagingUser.lastReadAt && this.room.createdById != Ingtech.user.id))
			this.status = 1;
		else
			this.status = 0;


		if (this.status == 2) {
			dot.addClass('dot-critical-red');
		} else if (this.status == 1) {
			dot.addClass('dot-primary');
		} else {
			dot.addClass('dot-light-gray');
		}
	}

	appendTo($element) {
		$element.append(this.$roomBanner);
	}

	onClick(listener) {
		this.$roomBanner.click(() => {
			listener(this.room, this);
		});
	}

	selected(selected) {
		this.$roomBanner.toggleClass('selected', selected);
	}
}